import getRoute from '~/utilities/configs/apiMap';
import { profilePageStore } from '~/store/profilePage';
import { authStore } from '~/store/auth';
import type { CompanyItem } from '~/types/company-page-items';
import { apiGet, apiPost } from '~/services/api';
import type { ProfileItem } from '~/types/profile-page-items';

export default defineNuxtRouteMiddleware(async (to) => {
  const useProfilePageStore = profilePageStore();

  const { id } = to.params;
  console.log(id);
  if (!id) {
    return navigateTo('/404');
  }

  try {
    if (id === '1') {
      const description = `<h3><font size="5"><span style="white-space-collapse: preserve;"><b>Main Goal</b></span></font></h3><div><span style="white-space-collapse: preserve;"><b>"Roots for Tomorrow</b>" is a transformative tree-planting campaign dedicated to revitalizing remote communities by planting<b> 10,000 trees over the next two years</b>. Our mission is to enhance local biodiversity, sequester carbon, and foster sustainable development by engaging and empowering local communities. Through collaborative efforts with residents, environmental experts, and dedicated volunteers, we aim to create thriving green spaces that benefit both the environment and the people.</span><br></div><div><span style="white-space-collapse: preserve;"><br></span></div><div><font size="5"><span style="white-space-collapse: preserve;"><b>Key Objectives</b></span></font></div><div><font size="5"><span style="white-space-collapse: preserve;"><b><br></b></span></font><div><ul><li><span style="white-space-collapse: preserve;"><font size="2"><b>Environmental Restoration</b>: Plant 10,000 trees to improve soil quality, increase biodiversity, and combat climate change.</font></span></li></ul><ul><li><font size="2" style=""><span style="white-space-collapse: preserve;"><b>Community Empowerment</b>: Engage local residents through education, training, and employment opportunities related to the project.</span></font></li></ul><ul><li><font size="2" style=""><span style="white-space-collapse: preserve;"><b>Sustainable Development</b>: Foster long-term environmental stewardship and sustainable practices within the community.</span></font></li></ul><ul><li><font size="2" style=""><span style="white-space-collapse: preserve;"><b>Carbon Sequestration</b>: Offset carbon emissions and contribute to global climate change mitigation efforts.</span></font></li></ul></div></div><div></div>`;
      const legal = `Lorem ipsum dolor sit amet consectetur. Et eu magna quis ullamcorper lobortis urna pellentesque volutpat. Tristique tempor risus tellus massa enim sit. Eu arcu vitae placerat viverra arcu etiam sit porta libero. Quis faucibus risus posuere viverra ipsum. Cras venenatis molestie nibh viverra nisi pretium ultricies. Quis porta at eu nisl malesuada dictum quam lectus ullamcorper.<div>Commodo curabitur vitae ac adipiscing orci. At volutpat maecenas faucibus morbi. Ipsum massa viverra quis nec justo laoreet. Imperdiet tempus ipsum orci mattis cursus vulputate sed ultricies. Tempor a luctus maecenas purus adipiscing amet consequat sem nulla. Magna mattis sit sed nibh lacus. Sit at sit diam ipsum nec id tincidunt habitant.<br></div>`;
      const comapanyLogoFile = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpg",
        id: "c5351749-e1b9-4fdc-9358-6b57296ab5e1",
        name: "avatar.jpg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_company_header_avatar.png`,
      }

      const heroImageFile = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpg",
        id: "c5351749-e1b9-4fdc-9358-6b57296ab5e1",
        name: "hero.jpg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_company_header_img.png`,
      }

      const galleryImageFile1 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpeg",
        id: "1",
        name: "1.jpeg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_comapny_gallery_1.jpeg`,
      }
      const galleryImageFile2 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpeg",
        id: "2",
        name: "2.jpeg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_comapny_gallery_2.jpeg`,
      }

      const galleryImageFile3 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpeg",
        id: "3",
        name: "3.jpeg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_comapny_gallery_3.jpeg`,
      }

      const galleryImageFile4 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpeg",
        id: "4",
        name: "4.jpeg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_comapny_gallery_4.jpeg`,
      }

      const galleryImageFile5 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpg",
        id: "5",
        name: "5.jpg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_campaign_card_image_1.jpg`,
      }

      const galleryImageFile6 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpg",
        id: "6",
        name: "6.jpg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_campaign_card_image_2.jpg`,
      }

      const galleryImageFile7 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpg",
        id: "7",
        name: "7.jpg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_campaign_card_image_3.jpg`,
      }

      const galleryImageFile8 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "jpg",
        id: "8",
        name: "8.jpg",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_campaign_card_image_4.jpg`,
      }

      const documentsPDFFile1 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "pdf",
        id: "1",
        name: "test_lorem_ipsum_1.pdf",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_lorem_ipsum_1.pdf`,
      }

      const documentsPDFFile2 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "pdf",
        id: "2",
        name: "test_lorem_ipsum_2.pdf",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_lorem_ipsum_2.pdf`,
      }

      const documentsPDFFile3 = {
        createdAt: "2024-10-09T13:13:57.576Z",
        ext: "pdf",
        id: "3",
        name: "/test_lorem_ipsum_lorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsum2.pdf",
        originUrl: null,
        updatedAt: "2024-10-09T13:13:57.580Z",
        url: `/test_lorem_ipsum_lorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsum2.pdf`,
      }

      useProfilePageStore.$state.profilePageData = {
        id: '1',
        companyName: 'Company Name',
        primaryEmail: 'example@company.com',
        companyLogo: {file:comapanyLogoFile},
        heroImage: {file:heroImageFile},
        keywords: ['keyword1', 'keyword2', 'keyword3'],
        description: description,
        youTubeLinks: [
          'https://youtu.be/M2f-66jP8VQ?feature=shared',
        ],
        // pdfDocuments: [
        //   `/test_lorem_ipsum_1.pdf`,
        //   `/test_lorem_ipsum_2.pdf`,
        //   `/test_lorem_ipsum_lorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsumlorem_ipsum2.pdf`,
        // ],
        pdfDocuments: [
          { file:documentsPDFFile1, id: '1', fileId:"1" },
          { file:documentsPDFFile2, id: '2', fileId:"2" },
          { file:documentsPDFFile3, id: '3', fileId:"3" },
        ],
        legalInfo: legal,
        newsletters: true,
        newsReports: true,
        contactUs: 'company@email.com<div><br></div><div>+1234567890<br></div><div><br></div><div><a href="https://facebook.com">facebooklink</a><br></div>',
        galleryImages: [
          { file:galleryImageFile1, id: '1', fileId:"1" },
          { file:galleryImageFile2, id: '2', fileId:"2" },
          { file:galleryImageFile3, id: '3', fileId:"3" },
          { file:galleryImageFile4, id: '4', fileId:"4" },
          { file:galleryImageFile5, id: '5', fileId:"5" },
          { file:galleryImageFile6, id: '6', fileId:"6" },
          { file:galleryImageFile7, id: '7', fileId:"7" },
          { file:galleryImageFile8, id: '8', fileId:"8" },
        ],

      };
    } else {
      const endpoint = getRoute({
        endpont: 'get_profile_by_id',
        id: id as string,
      });
      const token = localStorage.getItem('acc_token');
      const response = await apiGet({
        url: endpoint,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('middle data', response?.data.value);
      if (response?.status.value === 'success') {
        const profileObject = response?.data.value;

        if (profileObject) {
          useProfilePageStore.$state.profilePageData =
            profileObject as ProfileItem;
        }
      } else {
        return navigateTo('/404');
      }
    }
  } catch (error) {
    return navigateTo('/404');
  }
});
